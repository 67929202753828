<template>
    <defaultSec class="qrcode-detail" :title="'< 返回'"  :returnState="true" :isFlexSection="true">
        <section class="qrcode-list">
            <div class="route-right">
                <el-card header="基本信息" size="mini" style="margin-bottom: 10px">
                    <el-form ref="form" :model="qrcodeInfo" label-width="80px">
                        <el-form-item label="活码名称">
                            <el-input v-model="qrcodeInfo.qrName" placeholder="请输入活码名称"></el-input>
                        </el-form-item>
                        <el-form-item label="活码分组">
                            <el-select style="width: 100%" v-model="qrcodeInfo.groupId" placeholder="请选择活码分组">
                                <el-option v-for="item in groupList" :label="item.qrcodeGroupName" :value="item.qrcodeGroupId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="活码渠道">
                            <el-input v-model="qrcodeInfo.state" placeholder="请输入渠道"></el-input>
                        </el-form-item>
                        <el-form-item label="客户标签">
                            <customerTabs :tagVoList="qrcodeInfo.tagVoList" @selectTags="selectTags" @handleCloseTag="handleCloseTag"></customerTabs>

                            <!--                            <el-tag style="margin: 0 5px 5px 0" closable @close="handleCloseTag(index)" type="primary" size="small" v-for="(item,index) in qrcodeInfo.tagVoList||[]">{{item.tagName}} </el-tag>-->
<!--                            <div>-->
<!--                                <el-tag @click="addNewTag" style="margin: 0 5px 5px 0;cursor: pointer;font-weight: 600" effect="dark" type="primary" size="small">+ 新标签</el-tag>-->
<!--                            </div>-->
                        </el-form-item>

                        <el-divider></el-divider>
                        <el-form-item label="活码类型">
                            <el-radio size="mini" v-model="qrcodeInfo.type" :label="1" border>单人</el-radio>
                            <el-radio size="mini" v-model="qrcodeInfo.type" :label="2" border>多人</el-radio>
                        </el-form-item>
                        <el-form-item label="活码员工">
                            <div>
                                <customerServiceList :multipleType="qrcodeInfo.type==1?1:0" :selectUsers="qrcodeInfo.userList" @selectUsers="selectUsers"></customerServiceList>
                            </div>
                            <div style="font-size: 10px;color: #666666">* 单人活码只能选择一个员工，多人活码支持选择多个员工</div>
                        </el-form-item>
                    </el-form>
                </el-card>
                <el-card header="欢迎素材" size="mini" style="margin-bottom: 10px">
                    <el-form ref="form" :model="qrcodeInfo" label-width="80px">
                        <el-form-item label="素材选择">
                            <materialSel :materialVoList="qrcodeInfo.qrcodeAttachmentVoList" @selectMaterial="selectMaterial" @handleCloseMaterial="handleCloseMaterial"></materialSel>
                            <div style="font-size: 10px;color: #666666">* 可选择文本素材和附件素材</div>
                        </el-form-item>
                    </el-form>
                </el-card>
                <div style="display: flex;justify-content: flex-end;margin-bottom: 10px">
                    <el-button  type="primary" @click="submitQrcode">保存活码</el-button>
                </div>
            </div>
            <div class="route-left">
                <el-card size="mini" style="margin-bottom: 10px;">
                    <div slot="header" class="clearfix">
                        <span>员工活码</span>
                        <el-button v-if="qrcodeInfo.qrPath" @click="downloadCode" style="float: right; padding: 3px 0" type="text">下载</el-button>
                    </div>
                    <el-image
                        style="width: 200px; height: 200px;margin: 0 auto;display: block;"
                        :src="qrcodeInfo.qrPath"
                        :preview-src-list="[qrcodeInfo.qrPath]">
                        <div slot="error" class="image-slot">
                            <el-empty :image-size="70" description="暂无活码"></el-empty>
                        </div>
                    </el-image>
                </el-card>
                <div class="material-item">
                    <div class="left-title">
                        <span class="el-icon-arrow-left"></span>
                        <span>乐纯的伙伴</span>
                        <span class="el-icon-more"></span>
                    </div>
                    <div v-for="item in qrcodeInfo.qrcodeAttachmentVoList||[]" class="chat-item">
                        <div class="chat-img"><el-avatar size="small" src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg"></el-avatar></div>
                        <div class="chat-content" v-if="item.materialType=='text'||item.mediaType=='text'" v-html="item.pushText"></div>
                        <div class="chat-content chat-image" v-else-if="item.mediaType=='media'||item.mediaType=='image'||item.materialType=='image'||item.materialType=='emotion'">
                            <el-image :src="item.mediaUrl||item.ossUrl" :preview-src-list="[item.mediaUrl||item.ossUrl]"></el-image>
                        </div>
                        <div class="chat-content" v-else>[ {{item.materialType}} ]</div>
                    </div>
                </div>

            </div>
        </section>
    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'
import customerTabs from '@c/scrm/publicComponent/customerTabs'
import customerServiceList from "@c/scrm/publicComponent/customerServiceList";
import materialSel from "@c/scrm/publicComponent/materialSel";
export default {
    data(){
        return{
            qrcodeId:this.$route.params.id||'0',
            formParam: {
                pageSize: 10,
                currentPage: 1,
                materialCode: '',
                materialName: '',
                materialType: 'text',
                groupId: '',
                status: 1,
                firstSnedTime: null,
                date: null,
            },
            total:0,
            qrcodeInfo:{
                type:1,
                tagVoList:[],
                userList:[],
                qrcodeAttachmentVoList:[]
            },
            groupList:[],
            tagList:[],
            selTagList:[],
            tagDialogLoading:false,
            tagDialogVisible:false,
            materialDialogLoading:false,
            materialList:[],
            selMaterialList:[],
        }
    },
    watch:{
        '$route'(to,from){
            //监听路由变化
            if(to.params.id!==from.params.id){

            }
        }
    },
    components: {defaultSec,tablePagination,customerTabs,customerServiceList,materialSel},
    mounted() {
        if(this.qrcodeId!='0'){
            this.initData()
        }
        this.initGroup()

    },
    methods:{
        //活码分组
        initGroup(){
            this.$apiGet('lechun-cms/scrmQrcode/getQrcodeGroupList', {currentPage: 1,pageSize: 10000}).then(res => {
                this.groupList = res
            })
        },
        initData(){
            this.$apiGet('lechun-cms/scrmQrcode/getQrcode', { qrcodeId:this.qrcodeId}).then(res => {
                console.log(res)
                this.qrcodeInfo = res
            })
        },
        //选择客服
        selectUsers(val){
            this.qrcodeInfo.userList = val.value
        },
        //标签提交
        selectTags(val){
            console.log(val)
            this.qrcodeInfo.tagVoList = val.value
        },
        //标签删除
        handleCloseTag(index){
            this.qrcodeInfo.tagVoList.splice(index,1)
        },
        //素材选择
        selectMaterial(val){
            this.qrcodeInfo.qrcodeAttachmentVoList = [...this.qrcodeInfo.qrcodeAttachmentVoList||[],...val.value]
        },
        //素材删除
        handleCloseMaterial(index){
            this.qrcodeInfo.qrcodeAttachmentVoList.splice(index,1)
        },
        submitQrcode(){
            let formData = {
                ...this.qrcodeInfo,
                tagVoList:(this.qrcodeInfo.tagVoList||[]).map(item=>{
                    item.wxContactTagId = item.tagId||item.wxContactTagId
                    return item
                }),
                scene:2,//	场景，1-在小程序中联系，2-通过二维码联系
                remark:'',
                skipVerify:false,//开关
                exclusive:false,//开关
                temp:false
            }
            console.log('保存',formData)
            this.$apiPost('lechun-cms/scrmQrcode/saveQrcode', { ...formData}).then(res => {
                console.log(res)
                this.$message({
                    message: '保存成功',
                    type: 'success',
                    onClose(){
                        window.history.go(-1)
                    }
                });
            })
        },
        downloadCode(){
            this.$downloadImage(this.qrcodeInfo.qrPath,this.qrcodeInfo.qrName)
        }
    }
}
</script>

<style lang="scss" rel="stylesheet/scss">

.qrcode-detail{
    p,h3,h4,h5{
        margin: 0;
        padding: 0;
    }
    .qrcode-list{
        display: flex;
        height: 100%;
    }
    .route-left{
        width: 280px;
        position: relative;
        z-index: 9;
        margin: 10px 10px 20px 0;
        box-sizing: border-box;
    }

    .material-item{
        height: calc(100% - 318px);
        border-radius: 15px;
        background: #f5f8fe;
        font-size: 12px;
        box-shadow: 1px 0 6px 0 rgba(0,0,0,.26);

    }
    .left-title{
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        padding: 15px 10px;
        border-radius: 15px 15px 0 0px;
        font-size: 14px;
        font-weight: 600;
        span{
            display: block;
            line-height: 24px;
        }
    }
    .route-right{
        flex: 1;
        height: calc(100% - 10px);
        background: #ffffff;
        border-radius: 0 5px 5px 0;
        padding: 0px 10px 10px 0px;
        margin: 10px 10px 0 10px;
    }
    .right-title{
        align-items: center;
        border-bottom: 1px solid #DCDFE6;
        padding-bottom: 24px;
        margin-bottom: 15px;
    }
    .chat-detail-wrap-content{
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.2);
        height: calc(100% - 150px);
        padding: 15px 20px;
        box-sizing: border-box;
    }
    .chat-item{
        position: relative;
        padding-top: 20px;
        display: flex;
        font-size: 12px;
        margin: 0 10px 20px 10px;
    }

    .chat-item-reverse{
        flex-direction: row-reverse;
    }
    .chat-img{
        width: 28px;
        display: block;
        margin-right: 10px;
    }
    .chat-msg-time{
        position: absolute;
        top: 0;
        left: 38px;
        font-size: 10px;
        color: #999999;
    }
    .chat-img img{
        width: 28px!important;
    }

    .chat-content{
        background: #ffffff;
        padding: 10px;
        border-radius: 5px;
        display: inline-block;
        white-space: pre-wrap;
        max-width: 65%;

    }
    .chat-image{
        img{
            width: 100px;
            display: block;
        }
    }
    .chat-image{
        background: transparent;
    }
    .chat-miniprogram{
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 10px;
        width: 200px;
    }
    .chat-voice{
        border-radius: 5px;
        padding: 10px;
        width:100px;
        background: #f5f5f5 url("http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/icon-voice.png") center left no-repeat;
        background-size: 16px auto;
        padding-left: 26px;
        background-position-x: 5px;
    }
    .voice-text{
        background: #f5f5f5;
        padding: 10px;
        border-radius: 5px;
        white-space: pre-wrap;
        max-width: 80%;
    }
    .drawer-content{
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.2);
        padding: 15px 20px;
        box-sizing: border-box;
    }
    .copy-text {
        white-space: pre-line;
        margin: 0;
        line-height: 18px;
        background: #f2f2f2;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 6px;
        max-height: 127px;
        overflow-y: scroll;
    }
}

</style>